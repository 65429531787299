import { Container } from "react-bootstrap";

const Contact = () => {
    return (
        <Container fluid className="px-sm-5 p-3">
            <h1>Contact</h1>
        </Container>
    );
};

export default Contact;
