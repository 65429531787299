import { Container } from "react-bootstrap";

const Blogs = () => {
    return (
        <Container fluid className="px-sm-5 p-3">
            <h1>Blogs</h1>
        </Container>
    );
};

export default Blogs;
